<template>
  <v-navigation-drawer
    elevation-1
    class="container-drawer shadow"
    v-model="drawerData"
    mini-variant-width="70"
    app
    color="#232323"
    dark
    @input="updateDrawer"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <v-img
            width="150"
            style="margin: 0 auto"
            :src="require('@/assets/img/logo-ikeda-2.png')"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <MenuSection
      v-for="section in sections"
      :key="section.title"
      :section="section"
      :titleHidden="titleHidden"
    />
  </v-navigation-drawer>
</template>

<script>
import MenuSection from "./MenuSection";

export default {
  name: "Sidebar",

  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    MenuSection,
  },

  data() {
    return {
      sections: [
        {
          title: "CAMPANHA",
          permissions: [1461, 1470, 1503, 1471, 1481, 1482, 1504],
          items: [
            {
              title: "Dashboard",
              icon: "mdi-view-dashboard-outline",
              route: "/dashboard",
              permissions: 1461,
              levels: 0,
            },
            {
              title: "Apuração",
              icon: "mdi-clipboard-list-outline",
              route: "/campanhas/apuracao",
              permissions: 1482,
              levels: 0,
            },
          ],
        },
      ],
      drawerData: true,
    };
  },

  methods: {
    updateDrawer(newValueDrawer) {
      this.drawerData = newValueDrawer;
      this.$emit("setDrawerResponsiveness", newValueDrawer);
    },
  },

  watch: {
    drawer(value) {
      this.drawerData = value;
    },
  },
};
</script>
<style scoped>
.shadow {
  box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.2),
    0 6px 20px 5px rgba(0, 0, 0, 0.19);
}
</style>
