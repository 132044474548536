<template>
  <v-app-bar elevation="0" color="#232323">
    <v-app-bar-nav-icon
      class="white--text"
      @click="activeDrawer"
    ></v-app-bar-nav-icon>
    <v-toolbar-title class="white--text" color="black">
      {{ currencyView }}
    </v-toolbar-title>
  </v-app-bar>
</template>
<script>
export default {
  name: "Appbar",

  props: {
    drawer: {
      type: Boolean,
      default: true,
    },

    currencyView: {
      type: String,
    },
  },

  data() {
    return {
      drawerData: this.drawer,
    };
  },

  watch: {
    drawer(value) {
      this.drawerData = value;
    },
  },

  methods: {
    activeDrawer() {
      this.drawerData = !this.drawerData;
      this.$emit("setDrawer", this.drawerData);
    },
  },
};
</script>

<style></style>
