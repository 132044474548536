<template>
  <v-container class="pa-0">
    <AppBar
      @setDrawer="drawer = $event"
      :drawer="drawer"
      :currencyView="nameView"
    />
    <SideBar @setDrawerResponsiveness="drawer = $event" :drawer="drawer" />
    <router-view></router-view>
  </v-container>
</template>
<script>
import AppBar from "./components/AppBar";
import SideBar from "./components/SideBar";
export default {
  components: {
    AppBar,
    SideBar,
  },

  data() {
    return {
      drawer: true,
      nameView: "",
    };
  },

  updated() {
    this.nameView = this.getView.name;
  },

  mounted() {
    this.nameView = localStorage.getItem("titlePage");
  },
};
</script>

<style></style>
