export default {
  methods: {
    currentUser() {
      const userData = localStorage.getItem("currentUser");
      return userData ? JSON.parse(userData) : {};
    },
    checkUserAndLogout() {
      const user = this.currentUser();
      if (Object.keys(user).length === 0) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
  },
  created() {
    this.checkUserAndLogout();
  },
};
