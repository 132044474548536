<template>
  <v-container class="pa-0 ma-0">
    <Desktop v-if="displayWidth >= 900" />
    <Mobile v-else />
  </v-container>
</template>

<script>
import Mobile from "./system/Mobile.vue";
import Desktop from "./system/Desktop.vue";
export default {
  components: {
    Mobile,
    Desktop,
  },

  data() {
    return {
      displayWidth: "",
    };
  },

  methods: {
    getDisplayWidth() {
      this.displayWidth = window.innerWidth;
    },
  },

  created() {
    this.getDisplayWidth();
  },
};
</script>

<style></style>
