import System from "@/layouts/Index";
import Personal from "@/views/personal/routes/index";
import User from "@/views/user/routes/index";
import Login from "@/views/login/routes/index";
import Video from "@/views/video/routes/index";

import userData from "@/mixin/currentUser";

export default [
  {
    path: "/",
    name: "Home",
    redirect: userData.is_admin ? "/dashboard" : "/lessons",
    component: System,
    meta: { isAuthenticated: true },
    children: [...Personal, ...User],
  },
  ...Login,
  ...Video,
];
