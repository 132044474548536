<template>
  <div>
    <v-img class="img-height" :src="imagePage[$route.name].image">
      <div class="user-avatar" v-if="$route.name == 'Profile'">
        <v-avatar color="#777777" size="70" class="mt-2">
          <v-icon dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-btn class="mt-4" text color="#FFD103" @click="logout()">
          sair
          <v-icon class="ml-2">mdi-logout</v-icon>
        </v-btn>
      </div>
      <v-btn
        v-if="$route.name == 'Users'"
        class="logout-btn-personal"
        text
        color="#FFD103"
        @click="logout()"
      >
        sair
        <v-icon class="ml-2">mdi-logout</v-icon>
      </v-btn>
    </v-img>
    <v-card class="limited-height" :color="changeColor()">
      <router-view></router-view>
    </v-card>
    <BottomNavigationPersonal v-if="userAccount.is_admin" />
    <BottomNavigation v-else />
  </div>
</template>
<script>
import BottomNavigation from "@/layouts/system/components/BottomNavigation.vue";
import BottomNavigationPersonal from "@/layouts/system/components/BottomNavigationPersonal.vue";
import chageImage from "@/mixin/chageImage";
import userData from "@/mixin/currentUser";

export default {
  name: "Portal",
  components: {
    BottomNavigation,
    BottomNavigationPersonal,
  },

  mixins: [chageImage, userData],

  data() {
    return {
      currentRoute: "",
      userAccount: {},
    };
  },

  methods: {
    changeColor() {
      return this.$router.history.current.name == "MentorshipLessons" ||
        this.$router.history.current.name == "LessonsMentorshipLessons"
        ? "#101010"
        : "#FFFFFF";
    },
    chageImage(route) {
      return this.imagePage[route].image;
    },

    async fetchCurrentUser() {
      this.userAccount = this.currentUser();
    },

    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },

  created() {
    this.fetchCurrentUser();
  },
};
</script>
<style scoped>
.img-height {
  height: 26vh;
}
.limited-height {
  height: 70vh;
  overflow-y: scroll;
  z-index: 1;
  margin-top: -20px;
  border-radius: 20px 20px 0px 0px;
}

.user-avatar {
  position: absolute;
  width: 100px;
  top: 17px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.logout-btn-personal {
  position: absolute;
  bottom: 30px;
}
</style>
