export default [
  {
    path: "/mentorship/:link",
    name: "MentorshipVideo",
    component: () => import("@/components/mentorship/MentorshipVideo"),
    meta: { isAuthenticated: true },
  },
  {
    path: "/lessons/:link",
    name: "LessonsVideo",
    component: () => import("@/components/mentorship/MentorshipVideo"),
    meta: { isAuthenticated: true },
  },
];
