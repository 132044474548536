<template>
  <div class="ml-1" v-can-access="section.permissions">
    <v-list dense nav>
      <v-list-item :to="item.route">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
  </div>
</template>
<script>
export default {
  name: "TheMenuSection",

  props: {
    item: {
      type: Object,
    },
  },
};
</script>
<style scoped></style>
